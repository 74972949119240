/* ==================================================================================== *
 *
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 * & https://roots.io/sage/
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * ==================================================================================== *
 *
 * TweenMax
 * By GreenStock
 * https://greensock.com/tweenmax
 * https://ihatetomatoes.net/wp-content/uploads/2016/07/GreenSock-Cheatsheet-4.pdf
 *
 * ==================================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var analoxtheme = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

        // Add submenu wrapper
        let subMenu = $('.sub-menu');
        subMenu.wrap('<div class="sub-menu-wrapper"/>');

        // Toggle Class in dropdown
        let menu_item = $('.menu-item-has-children > a');
        menu_item.click(function () {
          $(this).parent().toggleClass('open');
          $(this).parent().siblings().removeClass('open');
        });

        // Swiper
        var swiper = new Swiper(".product-slider-thumb", {
        //direction: 'vertical',
      spaceBetween: 20,
      slidesPerView: "auto",
      breakpoints: {
        767: {
          direction: "vertical",
          slidesPerView: 3
        }
      }
        });


        let options = {};

        if ( $(".product-slider .swiper-slide").length == 1 ) {
            options = {
                direction: 'horizontal',
                loop: false,
                spaceBetween: 0,
                navigation: {
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                },
                thumbs: {
                  swiper: swiper,
                },
            }
        } else {
            options = {
                loop: false,
                autoplay: { 
                  "delay": 2000,
                  "disableOnInteraction": true,
                },
                spaceBetween: 0,
                navigation: {
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                },
                thumbs: {
                  swiper: swiper,
                },
            }
        }          

        var swiper = new Swiper('.product-slider', options);

        // var swiper2 = new Swiper(".product-slider", {
          
        // });

    var logo_swiper = new Swiper(".logo-slides", {
      //direction: 'vertical',
      spaceBetween: 40,
      slidesPerView: 6,
      slidesPerView:'auto',
      breakpoints: {
      320 : {
        slidesPerView: 1
      },
      767 : {
        slidesPerView: 4
      },
      1024 : {
        slidesPerView: 6
      }
      },
      centeredSlides: true,
      speed: 2000,
      autoplay: {
      delay: 1,
      },
      loop: true,
      allowTouchMove: false,
      disableOnInteraction: true
      });

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home/Index page example - if WordPress, 'index' will need to be changed to 'home'
    'home': {
      init: function () {
        // JavaScript to be fired on the home page

        // Sticky Regional CTA Button
        var fixmeTop = $('.region-calltoaction').offset().top - 10;
        $(window).scroll(function () {
          var currentScroll = $(window).scrollTop();
          if (currentScroll >= fixmeTop) {
            $('.region-calltoaction').css({
              position: 'fixed',
              top: '0',
              left: '0'
            });
          } else {
            $('.region-calltoaction').css({
              position: 'static'
            });
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
    // ...
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = analoxtheme;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
